export const TAG_LIST = [
  "Database",
  "System Design",
  "Algorithm",
  "Backend",
  "Go",
  "Networking",
  "OSystem",
  "English",
  "Frontend",
  "Vim",
  "Cloud",
  "Design Pattern",
  "SQL",
];

export const LANGUAGE_LIST = ["go", "python", "javascript", "typescript"];
